import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import PartnerSmall from "../components/Common/PartnerSmall";
import AccordionSection from "../components/Common/AccordionSection";

import Img from "../assets/images/org-structure/org-structure.png";

const OrganizationStructure = ({ pageContext: { lang, data }, location: { pathname } }) => (
    <Layout>
        <Seo
            title={data.seoTitle}
            lang={lang}
        /> 

        <Navbar
            currentPath={pathname}
            lang={lang}
        />

        <PageBanner pageTitle={data.bannerTitle} />

        <div className="features-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 features-details-image">
                        <img 
                            src={Img} 
                            className="animate__animated animate__fadeInUp" 
                            alt={data.pageTitle} 
                        />
                    </div>

                    <div className="col-lg-6 features-details">
                        <div className="features-details-desc">
                            <h3>{data.pageTitle}</h3>
                            <p>{data.text1}</p>

                            <p>{data.text2}</p>

                            <ul className="features-details-list">
                                {data.list.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-start">
                    <AccordionSection lang={lang} />
                </div>
            </div>
        </div>

        <PartnerSmall lang={lang} />

        <Footer lang={lang} />
    </Layout>
)

export default OrganizationStructure;
